import React, { useState, useContext } from "react"
import Layout from "@components/Layout/Layout";
import Container from "@components/Container/Container.jsx";
import ButtonLink from "@components/Buttons/ButtonLink.jsx"
import MainWrap from "@components/Wrap/MainWrap";
import { Typography } from "@material-ui/core";
import { Box, Grid, CardMedia } from "@material-ui/core";
import { makeComponentStyles } from "@components/mixins.js"
import { List, ListItem } from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { graphql } from "gatsby";
import Markdown from 'markdown-to-jsx';
import { IntlContextConsumer, useIntl } from "gatsby-plugin-react-intl";
import { getStrapiContentByLang } from "../heplers.js";
import Seo from "@components/seo"

const styles = ({ palette, breakpoints }) => ({
  text: {
    fontSize: "18px",
    color: palette.text.primary,
    [breakpoints.down('sm')]: {
      fontSize: "14px",
      color: palette.text.black
    }
  },
  list: {
    '& $li': {
      borderRadius: '3px !important',
      margin: '2px 0',
      background: '#F6F6F6',
      '& $button': {
        fontSize: '18px',
        width: '100%',
        justifyContent: 'flex-start',
        padding: '6px 0'
      }
    }
  },
  selectmode: {
    background: `${palette.primary.main} !important`,
    color: palette.white.main
  },
  tab_images: {
    marginTop: 15
  }
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

const Housing = ({ data }) => {
  const {
    text,
    list,
    selectmode,
    tab_images,
  } = makeComponentStyles(styles);

  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));
  const [tab, setTab] = useState(0);
  const { language } = useContext(IntlContextConsumer);
  const intl = useIntl();
  const {
    head,
    content,
    HousingSEO
  } = getStrapiContentByLang(data.allStrapiHousing.edges, language);

  return (
    <div>
      <Seo title={HousingSEO?.TitleSEO}
           description={HousingSEO?.DescriptionSEO}
           keywords={HousingSEO?.KeywordsSEO.split(',')}
      />

      <>
        <Layout>
          <Container>
            <MainWrap>
              <MainWrap.Header>
                <Typography component={"h1"} variant={"h2"}>
                  {intl.formatMessage({ id: 'housing' })}
                </Typography>
              </MainWrap.Header>
              <MainWrap.Body>
                <Typography className={text}>
                  <Markdown style={{ listStylePosition: 'inside' }}>{head}</Markdown>
                </Typography>
                <Grid container spacing={isDesktop ? 6 : 2} style={{ paddingTop: isDesktop ? '37px' : '24px' }}>
                  <Grid item xs={12} md={3}>
                    <List disablePadding className={list}>
                      {
                        content.map((item, index) => (
                          <ListItem className={tab === index ? selectmode : ''}>
                            <ButtonLink onClick={() => setTab(index)}>{item.title}</ButtonLink>
                          </ListItem>
                        ))
                      }
                    </List>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    {
                      content.map(({description, images}, index) => (
                        <TabPanel value={tab} index={index}>
                          <Typography className={text}>
                            <Markdown style={{ listStylePosition: 'inside' }}>{description}</Markdown>
                          </Typography>
                          <Grid container spacing={4} className={tab_images}>
                            {
                              images.map(item => (
                                <Grid item xs={12} md={6}>
                                  <CardMedia
                                    component="img"
                                    image={item.localFile.publicURL}
                                    alt='photo'
                                  />
                                </Grid>
                              ))
                            }
                          </Grid>
                        </TabPanel>
                      ))
                    }
                  </Grid>
                </Grid>
              </MainWrap.Body>
            </MainWrap>
          </Container>
        </Layout >
      </>
    </div>
  )
}
export default Housing;
export const pageQuery = graphql`{
  allStrapiHousing {
    edges {
      node {
        locale
        head
        content {
          title
          description
          images {
            localFile {
              publicURL
            }
          }
        }
        HousingSEO {
          DescriptionSEO
          KeywordsSEO
          TitleSEO
        }
      }
    }
  }
}`
